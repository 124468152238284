import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Confirm, Form, Icon, Message, Modal, Segment, Tab } from 'semantic-ui-react';
import { CustomersContext } from '../CustomersContext';
import { useForm } from '../../../Hooks/useForm';
import { integerKeyDown, floatKeyDown } from '../../../Services/inputMasks';
import EmployeePlanTable from './EmployeePlanTable';
import PlanInfoMedical from './PlanInfoMedical';
import PlanInfoDental from './PlanInfoDental';
import PlanInfoVision from './PlanInfoVision';

const MedicalPlansModal = props => {
	// context
	const { mappedWsPlans, wsPlans, postMedicalPlan } = useContext( CustomersContext );

	// props
	const { plan, setPlan, medicalPlansModal, setMedicalPlansModal } = props;

	const defaultInfo = useCallback(
		typeId => typeId === 6 // default values for medical plans
			? {
				field_C1: 'see below',
				field_F1: 'covered 100% deductible waived'
			}
			: {},
		[]
	);

	// form handler
	const { formData, updateField, setData } = useForm( { insuranceType: 63 } );
	const { formData: info, updateField: updateInfo, setData: setInfo } = useForm( {} );

	// state
	const [ validator, setValidator ] = useState( {
		opsPlanID: 1,
		insuranceType: 1,
		planName: 'string',
		eE_Amount: 1,
		eE_Quantity: 1,
		eS_Amount: 1,
		eS_Quantity: 1,
		eC_Amount: 1,
		eC_Quantity: 1,
		eF_Amount: 1,
		eF_Quantity: 1,
		employees: []
	} );
	const [ planType, setPlanType ] = useState( 0 );
	const [ planTypeWarningModal, setPlanTypeWarningModal ] = useState( false );
	const [ onPlanTypeWarningModalConfirm, setOnPlanTypeWarningModalConfirm ] = useState( () => () => null );

	const handlePlanType = useCallback( planId => {
		const equivPlan = wsPlans.find( plan => plan.id === planId );

		if ( equivPlan ) {
			setPlanType( equivPlan.type.id );
			setInfo( defaultInfo( equivPlan.type.id ) );
		}

		if ( plan && plan.opsPlanID ) {
			const prevEquivPlan = wsPlans.find( ePlan => ePlan.id === plan.opsPlanID );

			if ( prevEquivPlan.type.id !== equivPlan.type.id ) {
				setOnPlanTypeWarningModalConfirm( () => () => {
					updateField( { target: { name: 'opsPlanID', value: planId } } );
				} );

				setPlanTypeWarningModal( true );
			} else {
				updateField( { target: { name: 'opsPlanID', value: planId } } );
			}
		} else if ( formData.opsPlanID ) {
			const prevEquivPlan = wsPlans.find( ePlan => ePlan.id === formData.opsPlanID );

			if ( prevEquivPlan?.type.id !== equivPlan.type.id ) {
				setOnPlanTypeWarningModalConfirm( () => () => {
					updateField( { target: { name: 'opsPlanID', value: planId } } );
				} );

				setPlanTypeWarningModal( true );
			} else {
				updateField( { target: { name: 'opsPlanID', value: planId } } );
			}
		} else {
			updateField( { target: { name: 'opsPlanID', value: planId } } );
		}
	}, [ wsPlans, plan, formData.opsPlanID, updateField, setInfo, defaultInfo ] );

	useEffect( () => {
		if ( plan ) {
			const equivPlan = wsPlans.find( ePlan => ePlan.id === plan.opsPlanID );

			if ( equivPlan ) {
				setPlanType( equivPlan.type.id );
			}
		}
	}, [ plan, setPlanType, wsPlans ] );

	// set formData to customer data
	useEffect( () => {
		if ( plan ) {
			const planData = {
				...plan,
				insurancePlanType: 6,
				insuranceType: plan.insuranceType.id
			};

			setData( planData );
			setInfo( { ...plan.info } );
		}

		// on unmount
		return () => {
			setData( { insuranceType: 63 } );
		};
	}, [ plan, setData, setInfo ] );

	// prepare data for api call (update)
	const handleUpdate = () => {
		postMedicalPlan( {
			...plan,
			...formData,
			insurancePlanType: { id: 6 },
			insuranceType: { id: formData.insuranceType },
			info
		} );
		setMedicalPlansModal( false );
	};

	// prepare data for api call (create)
	const handleCreateNew = () => {
		postMedicalPlan( {
			id: 0,
			...formData,
			insurancePlanType: { id: 6 },
			insuranceType: { id: formData.insuranceType },
			info: {
				...info,
				field_C1: info.field_c1 || 'see below',
				field_F1: info.field_F1 || 'covered 100% deductible waived'
			}
		} );
		setMedicalPlansModal( false );
	};

	// clear out record, formData, and validator when modal closes
	const clearData = () => {
		setPlan( '' );
		setPlanType( 0 );
		setInfo( {} );
		setData( { insuranceType: 63 } );
		setValidator( {
			opsPlanID: 1,
			insuranceType: 1,
			planName: 'string',
			eE_Amount: 1,
			eE_Quantity: 1,
			eS_Amount: 1,
			eS_Quantity: 1,
			eC_Amount: 1,
			eC_Quantity: 1,
			eF_Amount: 1,
			eF_Quantity: 1
		} );
	};

	const panes = [
		{
			menuItem: 'Cost',
			render: () => (
				<Tab.Pane as={ Segment }>
					<Form>
						<Form.Group>
							<Form.Field required width={ 8 }>
								<label>Plan Type</label>
								<Form.Group inline>
									<Form.Radio
										id="plan-type-group"
										name="insuranceType"
										label="Group"
										value={ 63 }
										checked={ parseInt( formData.insuranceType ) === 63 }
										onChange={ updateField }
										disabled={ Boolean( formData.id ) }
									/>
									<Form.Radio
										id="plan-type-individual"
										name="insuranceType"
										label="Individual"
										value={ 62 }
										checked={ parseInt( formData.insuranceType ) === 62 }
										onChange={ updateField }
										disabled={ Boolean( formData.id ) }
									/>
								</Form.Group>
							</Form.Field>
							<Form.Select
								required
								name="opsPlanID"
								label="Equivalent WorkSmart Plan"
								width={ 8 }
								value={ formData.opsPlanID || 0 }
								options={ [ { key: 0, text: 'Select an option', value: 0 }, ...mappedWsPlans ] }
								onChange={ ( e, { value } ) => {
									handlePlanType( value );
								}}
								error={ validator.opsPlanID === 0 }
								onBlur={ () => {
									setValidator( { ...validator, opsPlanID: formData.opsPlanID || 0 } );
								}}
								onFocus={ () => {
									setValidator( { ...validator, opsPlanID: 1 } );
								}}
							/>
						</Form.Group>
						<Message
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
							hidden={ validator.opsPlanID > 0 }
						>
							<Message.List
								hidden={ validator.opsPlanID > 0 }
							>
								<Message.Item>
									<Icon name="exclamation circle" />
									Please select an equivalent WorkSmart plan.
								</Message.Item>
							</Message.List>
						</Message>

						<Form.Group>
							<Form.Input
								required
								name="planName"
								value={ formData.planName || '' }
								label="Plan Name"
								width={ 16 }
								maxLength={ 125 }
								onChange={ updateField }
								error={ validator.planName.length === 0 }
								onBlur={ () => {
									setValidator( { ...validator, planName: formData.planName || '' } );
								}}
								onFocus={ () => {
									setValidator( { ...validator, planName: 'string' } );
								}}
							/>
						</Form.Group>
						<Message
							negative
							style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
							hidden={ validator.planName.length > 0 }
						>
							<Message.List>
								<Message.Item
									hidden={ validator.planName.length > 0 }
								>
									<Icon name="exclamation circle" />
									Please enter a plan name.
								</Message.Item>
							</Message.List>
						</Message>

						{ parseInt( formData.insuranceType ) === 63 // group
							? <>
								<Form.Group>
									<Form.Input
										required
										type="number"
										name="eE_Amount"
										value={ parseFloat( formData.eE_Amount ) >= 0 ? formData.eE_Amount : '' }
										step={ 0.01 }
										icon="dollar sign"
										iconPosition="left"
										label="EE Cost"
										width={ 4 }
										min={ 0 }
										onChange={ updateField }
										error={ !( parseFloat( validator.eE_Amount ) >= 0 ) }
										onBlur={ e => {
											updateField( { target: { name: e.target.name, value: e.target.value ? parseFloat( e.target.value ).toFixed( 2 ) : '' } } );
											setValidator( { ...validator, eE_Amount: parseFloat( formData.eE_Amount ) >= 0 ? parseFloat( formData.eE_Amount ) : '' } );
										}}
										onFocus={ () => {
											setValidator( { ...validator, eE_Amount: 0 } );
										}}
										onKeyDown={ floatKeyDown }
									/>
									<Form.Input
										required
										type="number"
										name="eE_Quantity"
										value={ parseInt( formData.eE_Quantity ) >= 0 ? formData.eE_Quantity : '' }
										label="Number of Participants"
										width={ 4 }
										min={ 0 }
										onChange={ updateField }
										error={ !( parseInt( validator.eE_Quantity ) >= 0 ) }
										onBlur={ () => {
											setValidator( { ...validator, eE_Quantity: parseInt( formData.eE_Quantity ) >= 0 ? formData.eE_Quantity : '' } );
										}}
										onFocus={ () => {
											setValidator( { ...validator, eE_Quantity: 0 } );
										}}
										onKeyDown={ integerKeyDown }
									/>

									<Form.Input
										required
										type="number"
										name="eS_Amount"
										value={ parseFloat( formData.eS_Amount ) >= 0 ? formData.eS_Amount : '' }
										step={ 0.01 }
										icon="dollar sign"
										iconPosition="left"
										label="ES Cost"
										width={ 4 }
										min={ 0 }
										onChange={ updateField }
										error={ !( parseFloat( validator.eS_Amount ) >= 0 ) }
										onBlur={ e => {
											updateField( { target: { name: e.target.name, value: e.target.value ? parseFloat( e.target.value ).toFixed( 2 ) : '' } } );
											setValidator( { ...validator, eS_Amount: parseFloat( formData.eS_Amount ) >= 0 ? parseFloat( formData.eS_Amount ) : '' } );
										}}
										onFocus={ () => {
											setValidator( { ...validator, eS_Amount: 0 } );
										}}
										onKeyDown={ floatKeyDown }
									/>
									<Form.Input
										required
										type="number"
										name="eS_Quantity"
										value={ parseInt( formData.eS_Quantity ) >= 0 ? formData.eS_Quantity : '' }
										label="Number of Participants"
										width={ 4 }
										min={ 0 }
										onChange={ updateField }
										error={ !( parseInt( validator.eS_Quantity ) >= 0 ) }
										onBlur={ () => {
											setValidator( { ...validator, eS_Quantity: parseInt( formData.eS_Quantity ) >= 0 ? formData.eS_Quantity : '' } );
										}}
										onFocus={ () => {
											setValidator( { ...validator, eS_Quantity: 0 } );
										}}
										onKeyDown={ integerKeyDown }
									/>
								</Form.Group>
								<Message
									hidden={
										parseFloat( validator.eE_Amount ) >= 0
											&& parseInt( validator.eE_Quantity ) >= 0
											&& parseFloat( validator.eS_Amount ) >= 0
											&& parseInt( validator.eS_Quantity ) >= 0
									}
									negative
									style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
								>
									<Message.List style={{ listStyle: 'none' }}>
										<Message.Item
											hidden={ parseFloat( validator.eE_Amount ) >= 0 }
										>
											<Icon name="exclamation circle" />
												Please enter a value for EE Cost.
										</Message.Item>
										<Message.Item
											hidden={ parseInt( validator.eE_Quantity ) >= 0 }
										>
											<Icon name="exclamation circle" />
												Please enter the number of EE Participants.
										</Message.Item>
										<Message.Item
											hidden={ parseFloat( validator.eS_Amount ) >= 0 }
										>
											<Icon name="exclamation circle" />
												Please enter a value for ES Cost.
										</Message.Item>
										<Message.Item
											hidden={ parseInt( validator.eS_Quantity ) >= 0 }
										>
											<Icon name="exclamation circle" />
												Please enter the number of ES Participants.
										</Message.Item>
									</Message.List>
								</Message>

								<Form.Group>
									<Form.Input
										required
										type="number"
										name="eC_Amount"
										value={ parseFloat( formData.eC_Amount ) >= 0 ? formData.eC_Amount : '' }
										step={ 0.01 }
										icon="dollar sign"
										iconPosition="left"
										label="EC Cost"
										width={ 4 }
										min={ 0 }
										onChange={ updateField }
										error={ !( parseFloat( validator.eC_Amount ) >= 0 ) }
										onBlur={ e => {
											updateField( { target: { name: e.target.name, value: e.target.value ? parseFloat( e.target.value ).toFixed( 2 ) : '' } } );
											setValidator( { ...validator, eC_Amount: parseFloat( formData.eC_Amount ) >= 0 ? parseFloat( formData.eC_Amount ) : '' } );
										}}
										onFocus={ () => {
											setValidator( { ...validator, eC_Amount: 0 } );
										}}
										onKeyDown={ floatKeyDown }
									/>
									<Form.Input
										required
										type="number"
										name="eC_Quantity"
										value={ parseInt( formData.eC_Quantity ) >= 0 ? formData.eC_Quantity : '' }
										label="Number of Participants"
										width={ 4 }
										min={ 0 }
										onChange={ updateField }
										error={ !( parseInt( validator.eC_Quantity ) >= 0 ) }
										onBlur={ () => {
											setValidator( { ...validator, eC_Quantity: parseInt( formData.eC_Quantity ) >= 0 ? formData.eC_Quantity : '' } );
										}}
										onFocus={ () => {
											setValidator( { ...validator, eC_Quantity: 0 } );
										}}
										onKeyDown={ integerKeyDown }
									/>

									<Form.Input
										required
										type="number"
										name="eF_Amount"
										value={ parseFloat( formData.eF_Amount ) >= 0 ? formData.eF_Amount : '' }
										step={ 0.01 }
										icon="dollar sign"
										iconPosition="left"
										label="EF Cost"
										width={ 4 }
										min={ 0 }
										onChange={ updateField }
										error={ !( parseFloat( validator.eF_Amount ) >= 0 ) }
										onBlur={ e => {
											updateField( { target: { name: e.target.name, value: e.target.value ? parseFloat( e.target.value ).toFixed( 2 ) : '' } } );
											setValidator( { ...validator, eF_Amount: parseFloat( formData.eF_Amount ) >= 0 ? parseFloat( formData.eF_Amount ) : '' } );
										}}
										onFocus={ () => {
											setValidator( { ...validator, eF_Amount: 0 } );
										}}
										onKeyDown={ floatKeyDown }
									/>
									<Form.Input
										required
										type="number"
										name="eF_Quantity"
										value={ parseInt( formData.eF_Quantity ) >= 0 ? formData.eF_Quantity : '' }
										label="Number of Participants"
										width={ 4 }
										min={ 0 }
										onChange={ updateField }
										error={ !( parseInt( validator.eF_Quantity ) >= 0 ) }
										onBlur={ () => {
											setValidator( { ...validator, eF_Quantity: parseInt( formData.eF_Quantity ) >= 0 ? formData.eF_Quantity : '' } );
										}}
										onFocus={ () => {
											setValidator( { ...validator, eF_Quantity: 0 } );
										}}
										onKeyDown={ integerKeyDown }
									/>
								</Form.Group>
								<Message
									hidden={
										parseFloat( validator.eC_Amount ) >= 0
											&& parseInt( validator.eC_Quantity ) >= 0
											&& parseFloat( validator.eF_Amount ) >= 0
											&& parseInt( validator.eF_Quantity ) >= 0
									}
									negative
									style={{ border: 'none', backgroundColor: 'initial', boxShadow: 'none', padding: 0 }}
								>
									<Message.List style={{ listStyle: 'none' }}>
										<Message.Item
											hidden={ parseFloat( validator.eC_Amount ) >= 0 }
										>
											<Icon name="exclamation circle" />
												Please enter a value for EC Cost.
										</Message.Item>
										<Message.Item
											hidden={ parseInt( validator.eC_Quantity ) >= 0 }
										>
											<Icon name="exclamation circle" />
												Please enter the number of EC Participants.
										</Message.Item>
										<Message.Item
											hidden={ parseFloat( validator.eF_Amount ) >= 0 }
										>
											<Icon name="exclamation circle" />
												Please enter a value for EF Cost.
										</Message.Item>
										<Message.Item
											hidden={ parseInt( validator.eF_Quantity ) >= 0 }
										>
											<Icon name="exclamation circle" />
												Please enter the number of EF Participants.
										</Message.Item>
									</Message.List>
								</Message>
							</>
						// individual
							: <EmployeePlanTable employeePlans={ formData.employees || [] } updateField={ updateField } />
						}
					</Form>
				</Tab.Pane>
			)
		},
		planType === 6
			? {
				menuItem: 'Info',
				render: () => (
					<Tab.Pane as={ Segment } style={{ overflow: 'auto', maxHeight: '600px' }}>
						<PlanInfoMedical info={ info } updateInfo={ updateInfo } />
					</Tab.Pane>
				)
			}
			: planType === 76
				? {
					menuItem: 'Info',
					render: () => (
						<Tab.Pane as={ Segment } style={{ overflow: 'auto', maxHeight: '600px' }}>
							<PlanInfoDental info={ info } updateInfo={ updateInfo } />
						</Tab.Pane>
					)
				}
				: planType === 77
					? {
						menuItem: 'Info',
						render: () => (
							<Tab.Pane as={ Segment } style={{ overflow: 'auto', maxHeight: '600px' }}>
								<PlanInfoVision info={ info } updateInfo={ updateInfo } />
							</Tab.Pane>
						)
					}
					: null
	];

	return (
		<>
			<Modal
				id="medical-plans-modal"
				open={ medicalPlansModal }
				onClose={ () => {
					setMedicalPlansModal( false );
				}}
				closeOnDimmerClick={ false }
			>
				<Modal.Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					{ plan ? 'Edit Plan' : 'Add New Plan' }
				</Modal.Header>
				<Modal.Content>
					<Tab panes={ panes } />
				</Modal.Content>
				<Modal.Actions>
					<Button
						primary
						content="Save"
						onClick={ () => {
							plan ? handleUpdate() : handleCreateNew();
							clearData();
							setMedicalPlansModal( false );
						}}
						disabled={
							( formData.opsPlanID ? validator.opsPlanID === 0 : true )
							|| ( formData.planName ? formData.planName.length === 0 || formData.planName.length > 125 : true )
							|| ( parseInt( formData.insuranceType ) === 63
								? !( parseFloat( formData.eE_Amount ) >= 0 )
										|| !( parseInt( formData.eE_Quantity ) >= 0 )
										|| !( parseFloat( formData.eS_Amount ) >= 0 )
										|| !( parseInt( formData.eS_Quantity ) >= 0 )
										|| !( parseFloat( formData.eC_Amount ) >= 0 )
										|| !( parseInt( formData.eC_Quantity ) >= 0 )
										|| !( parseFloat( formData.eF_Amount ) >= 0 )
										|| !( parseInt( formData.eF_Quantity ) >= 0 )
								: false )
						}
					>
					</Button>
					<Button
						style={{ backgroundColor: '#F58229', color: '#fff' }}
						content="Cancel"
						onClick={ () => {
							clearData();
							setMedicalPlansModal( false );
						}}
					>
					</Button>
				</Modal.Actions>
			</Modal>
			<Confirm
				className="delete-confirm-modal"
				open={ planTypeWarningModal }
				size="tiny"
				content={
					<div className="content" style={{ fontSize: '16px' }}>
						Are you sure you want to continue?
						<br />
						<br />
						Changing the Equivalent Plan Type will clear out any data previously stored in the &lsquo;Info&rsquo; Tab.
					</div>
				}
				cancelButton="Cancel"
				confirmButton="Continue"
				onCancel={ () => {
					setPlanTypeWarningModal( false );
				} }
				onConfirm={ () => {
					onPlanTypeWarningModalConfirm();
					setPlanTypeWarningModal( false );
				} }
			/>
		</>
	);
};

export default MedicalPlansModal;
