import React from 'react';
import { Divider, Form, Header, Segment } from 'semantic-ui-react';

import PlanInfoPopup from './PlanInfoPopup';

const PlanInfoVision = ({ info, updateInfo }) => {

	return (
		<Form>
			<Form.Group>
				<Form.Input
					name="field_A1"
					value={ info.field_A1 || '' }
					label="Routine Eye Exam Once Every 12 Months"
					width={ 8 }
					onChange={ updateInfo }
				/>
			</Form.Group>

			<Segment>
				<Header style={{ color: '#2654A3' }}>Eyeglass Frames</Header>
      	<Divider />
				<Form.Group>
					<Form.Input
						name="field_B1"
						width={ 8 }
						value={ info.field_B1 || '' }
						label="Description"
						onChange={ updateInfo }
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						name="field_B2"
						value={ info.field_B2 || '' }
						label={ <label>Bi-Yearly Frames Allowance&nbsp;<PlanInfoPopup content="Once every 24 months you may select an eyeglass frame and receive an allowance toward the purchase price" /></label> }
						width={ 8 }
						onChange={ updateInfo }
					/>
				</Form.Group>
			</Segment>
			
			<Segment>
				<Header style={{ color: '#2654A3' }}>Eyeglass Lenses (Standard)</Header>
      	<Divider />
				<Form.Group>
					<Form.Input
						name="field_B3"
						value={ info.field_B3 || '' }
						label="Description"
						width={ 8 }
						onChange={ updateInfo }
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						name="field_B4"
						value={ info.field_B4 || '' }
						label={ <label>Comments&nbsp;<PlanInfoPopup content="You may receive the following lens options covered in full after copay." /></label> }
						width={ 8 }
						onChange={ updateInfo }
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						name="field_B5"
						value={ info.field_B5 || '' }
						label="Standard Plastic Single Vision Lenses"
						width={ 8 }
						onChange={ updateInfo }
					/>
					<Form.Input
						name="field_C1"
						width={ 8 }
						value={ info.field_C1 || '' }
						label="Standard Plastic Bifocal Lenses"
						onChange={ updateInfo }
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						name="field_C2"
						value={ info.field_C2 || '' }
						label="Standard Plastic Trifocal Lenses"
						width={ 8 }
						onChange={ updateInfo }
					/>
				</Form.Group>
			</Segment>

			<Segment>
				<Header style={{ color: '#2654A3' }}>Eyeglass Lens Enhancements</Header>
      	<Divider />
				<Form.Group>
					<Form.Input
						name="field_C3"
						value={ info.field_C3 || '' }
						label="Description"
						width={ 8 }
						onChange={ updateInfo }
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						name="field_C4"
						value={ info.field_C4 || '' }
						label={ <label>Comments&nbsp;<PlanInfoPopup content="When obtaining covered eyewear from a Blue View Vision partner, you may add any one of the following lens enhancements." /></label> }
						width={ 8 }
						onChange={ updateInfo }
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						name="field_C5"
						value={ info.field_C5 || '' }
						label="Transition Lenses (child under age 19)"
						width={ 8 }
						onChange={ updateInfo }
					/>
					<Form.Input
						name="field_D1"
						width={ 8 }
						value={ info.field_D1 || '' }
						label="Transition Lenses (adult)"
						onChange={ updateInfo }
					/>
				</Form.Group>
				<Form.Group>
					<Form.Input
						name="field_E1"
						value={ info.field_E1 || '' }
						label="Standard Polycarbonate (child under age 19)"
						width={ 8 }
						onChange={ updateInfo }
					/>
					<Form.Input
						name="field_E2"
						value={ info.field_E2 || '' }
						label="Factory Scratch Coating"
						width={ 8 }
						onChange={ updateInfo }
					/>
				</Form.Group>
			</Segment>

<Segment>
	<Header style={{ color: '#2654A3' }}>Contact Lenses&nbsp;<PlanInfoPopup content="Your contact lens allowance can only be applied toward the first purchase of contacts you make during a benefit period. Any unused amount remaining cannot be used for subsequent purchases made during the same benefit period, nor any unused amount be carried over to the following benefit period." /></Header>
	<Divider />
	<Form.Group>
		<Form.Input
			name="field_E3"
			value={ info.field_E3 || '' }
			label="Description"
			width={ 8 }
			onChange={ updateInfo }
		/>
	</Form.Group>
	<Form.Group>
		<Form.Input
			name="field_E4"
			value={ info.field_E4 || '' }
			label={ <label>Comments&nbsp;<PlanInfoPopup content="You may choose contact lenses instead of eyeglass lenses and recieve an allowance toward the cost of a supply of contact lenses." /></label> }
			width={ 8 }
			onChange={ updateInfo }
		/>
	</Form.Group>
	<Form.Group>
		<Form.Input
			name="field_E5"
			value={ info.field_E5 || '' }
			label="Elective Convential Lenses"
			width={ 8 }
			onChange={ updateInfo }
		/>
		<Form.Input
			name="field_E6"
			width={ 8 }
			value={ info.field_E6 || '' }
			label="Elective Disposable Lenses"
			onChange={ updateInfo }
		/>
	</Form.Group>
	<Form.Group>
		<Form.Input
			name="field_E7"
			value={ info.field_E7 || '' }
			label="Non-Elective Contact Lenses"
			width={ 8 }
			onChange={ updateInfo }
		/>
	</Form.Group>
</Segment>
		</Form>
	);
};

export default PlanInfoVision;